import React, { useEffect } from 'react'
import { GoogleMap, useJsApiLoader, Polygon } from '@react-google-maps/api';

// fit to screen
const mapContainerStyle = {
    height: "100vh",
    width: "100vw",
};
  
// default center of Map
const centerAB = {
    lat: 53.85641021888965, 
    lng: -112.25646622464733  
};

const bounds = {
  north: 54.98223863838184, // point near Plamondon, AB
  east: -109.92659459906237, // point near Onion Lake, SK
  south: 52.65599852197163, // point near Forestburg, AB
  west: -115.05689478893004 // point near Mayerthorpe, AB
  
}

const options = {
  strokeColor: "#0000FF",
  strokeOpacity: 0.25,
  strokeWeight: 0,
  fillColor: "#0000FF",
  fillOpacity: 0.25
}

function ISPMap({ data }) {
  const [map, setMap] = React.useState(null) 

  // updates map on load, sets boundary
  useEffect(() => {
    if(map !== null) {
      map.fitBounds(bounds);
    }
  }, [map]);

  // loads map
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })

  // creates data for polygon paths
  const paths = () => {
    let paths = [];
    if(data.latadd !== 0) {
      for(let i = 0; i < data.polygons.length - 2 ; i++) {
        let lat = (data.polygons[i].vlat * data.height) + data.latadd;
        let longa = data.polygons[i].vlong * data.width + data.longadd;
        let longb = longa + data.width * data.polygons[i].count;

        let pt_a = {lat: lat+(data.height/2), lng: longa-(data.width/2)};
        let pt_b = {lat: lat+(data.height/2), lng: longb+(data.width/2)};
        let pt_c = {lat: lat-(data.height/2), lng: longb+(data.width/2)};
        let pt_d = {lat: lat-(data.height/2), lng: longa-(data.width/2)};

        paths.push([pt_a, pt_b, pt_c, pt_d]);
      }
    }
    return paths;
  }

  return isLoaded ? (
    <GoogleMap id="map" mapContainerStyle={mapContainerStyle} onLoad={map => {setMap(map)}} center={centerAB} zoom={8}>
    <Polygon paths={paths()} options={options}/>
    </GoogleMap>
  ) : <div>Failed to load. Please try refreshing.</div>
}

export default ISPMap;