import React, {useState, useEffect} from 'react';
import ISPMap from './Components/ISPMap.js';

import './index.css';

export default function App() {
  const [errorStatus, setErrorStatus] = useState(false);
  const [data, setData] = useState({latadd: 0, longadd: 0, height: 0, width: 0, polygons: []});

  // parses data from API
  const parseData = (data) => {
    let poly_data = data.split(';')
      
    let latadd = poly_data[0].replace('latadd=', '');
    let longadd = poly_data[1].replace('longadd=', '');
    let height = poly_data[2].replace('height=', '');
    let width = poly_data[3].replace('width=', '');

    poly_data = poly_data.splice(4);
    
    for(let i = 0; i < poly_data.length - 2; i++) {
      poly_data[i] = poly_data[i].replace('P(', '');
      poly_data[i] = poly_data[i].replace(')', '');
      let temp_poly = poly_data[i].split(',');
      poly_data[i] = {vlat: parseInt(temp_poly[0]), vlong: parseInt(temp_poly[1]), count: parseInt(temp_poly[2])}
    }

    setData({latadd: parseInt(latadd), longadd: parseInt(longadd), height: parseFloat(height), width: parseFloat(width), polygons: poly_data});
  };

  // gets data from API or from local storage depending on scenario
  useEffect(() => {
    const fetchData = () => {
      fetch('https://interface.mcsnet.ca/guest/ispmap', {
          method: 'GET',   
          headers: {
            'Content-Type': 'application/json',
          },
          cache: 'default',
        })
      .then(response => {
        if(response.status === 200) {
          return response.json();
        } else {
          throw new Error("Response Status != 200");
        }})
      .then(json => {
        let GET_data = json['object']['data'];
        localStorage.setItem('data', GET_data);
        localStorage.setItem('ts', Date.now())
        parseData(GET_data);
      })
      .catch((error) => {
        console.error('Error - ', error);
        setErrorStatus(true);
      });
    }

    if(!localStorage.getItem('data')) {
      fetchData();
    } else {
      if(((Date.now() - localStorage.getItem('ts')) / 1000) >= 172800) { // if last fetch was 48+ hours ago, fetch again
        fetchData();
      } else {
        parseData(localStorage.getItem('data'));
      }
    }
  }, []);

  return errorStatus ? (
    <div style={{textAlign: 'center', fontSize: '28px', marginTop: '40vh', marginBottom: '50vh'}}>Map is currently unavailable.</div>
  ) : <div className="App"><ISPMap data={data} /></div>
}